import React, { useState, useEffect } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Game.css"; // Ensure this file contains necessary styles
import next_icon from "../assets/next_icon.png"; // Adjust the path and extension as needed
import prev_icon from "../assets/prev_icon.png"; // Adjust the path and extension as needed
import pause_icon from "../assets/pause_icon.png"; // Adjust the path and extension as needed

function Game({ quizz }) {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [artistName, setArtistName] = useState("");
  const [songName, setSongName] = useState("");
  const [answers, setAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState({});
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    let timer;
    if (isPlaying && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isPlaying, timeLeft]);

  useEffect(() => {
    const currTrack = quizz.trackDetails[currentTrackIndex];
    setCurrentTrack(currTrack);
    setPreviewUrl(currTrack.preview_url);
  }, [currentTrackIndex, quizz.trackDetails]);

  const handleStart = () => {
    setGameStarted(true);
    setCurrentTrackIndex(0);
    setTimeLeft(30);
  };

  const handleNext = () => {
    setCurrentTrackIndex((prevIndex) =>
      prevIndex < quizz.trackDetails.length - 1 ? prevIndex + 1 : prevIndex
    );
    setTimeLeft(30);
    setIsPlaying(false);
  };

  const handlePrevious = () => {
    setCurrentTrackIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
    setTimeLeft(30);
    setIsPlaying(false);
  };

  const handleSaveAnswer = () => {
    const newAnswer = {
      quizzListId: quizz.id,
      songId: currentTrack.id,
      artistName,
      songName,
      timeElapsed: 0, // Replace with actual time elapsed if needed
      created: new Date().toISOString(),
    };
    setAnswers([...answers, newAnswer]);
    // Clear input fields after saving
    setArtistName("");
    setSongName("");
  };

  const handleSubmitAnswers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/quizz-answers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: 1, // Replace with actual user ID
            quizzListId: quizz.id,
            answers,
          }),
        }
      );
      if (response.ok) {
        setAnswers([]); // Clear answers after submission
      } else {
        console.error("Failed to submit answers");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };
  const progressValue = (timeLeft / 30) * 100;

  return (
    <div className="game-component">
      {!gameStarted ? (
        <>
          <p>{quizz.description}</p>
          <button className="start-button" onClick={handleStart}>
            Start
          </button>
        </>
      ) : (
        <div className="track-display">
          <div className="track-info">
            <h2>
              Song #{currentTrackIndex + 1} ( {quizz.trackDetails.length} )
            </h2>
            <audio
              key={previewUrl}
              controls
              className="audio-player"
              onPlay={() => {
                setIsPlaying(true);
              }}
              onPause={() => setIsPlaying(false)}
            >
              <source src={previewUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <CircularProgressbarWithChildren value={progressValue}>
              {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
              <img
                style={{ width: 40, marginTop: -5 }}
                src={`/logo.png`}
                alt="logo"
              />
              <div className="progress-bar-text">
                <strong>{timeLeft}s</strong> left
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="navigation">
            <button onClick={handlePrevious} className="nav-button">
              <img src={prev_icon} alt="Previous" />
            </button>
            <button onClick={handleNext} className="nav-button">
              <img src={pause_icon} alt="Pause" />
            </button>
            <button onClick={handleNext} className="nav-button">
              <img src={next_icon} alt="Next" />
            </button>
          </div>
          <div className="input-fields">
            <input
              type="text"
              placeholder="Artist Name"
              className="input-box"
              value={artistName}
              onChange={(e) => setArtistName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Song Name"
              className="input-box"
              value={songName}
              onChange={(e) => setSongName(e.target.value)}
            />
          </div>
          <button
            className="action-button save-button"
            onClick={handleSaveAnswer}
          >
            Save Answer
          </button>
          {currentTrackIndex === quizz.trackDetails.length - 1 && (
            <button
              className="action-button submit-button"
              onClick={handleSubmitAnswers}
            >
              Submit Answers
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default Game;
