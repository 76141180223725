// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/QuizzardHandwriting-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "QuizzardHandwriting";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  height: 100%;
  margin: 0;
  font-family: "QuizzardHandwriting", sans-serif !important;
}

* {
  font-family: "QuizzardHandwriting", sans-serif !important;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: 200% 200%;
  animation: backgroundShift 5s infinite alternate;
}

@keyframes backgroundShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,+DAA6E;EAC7E,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;;EAEE,YAAY;EACZ,SAAS;EACT,yDAAyD;AAC3D;;AAEA;EACE,yDAAyD;AAC3D;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,0BAA0B;EAC1B,gDAAgD;AAClD;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":["@font-face {\n  font-family: \"QuizzardHandwriting\";\n  src: url(\"./assets/fonts/QuizzardHandwriting-Regular.otf\") format(\"opentype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\nbody,\nhtml {\n  height: 100%;\n  margin: 0;\n  font-family: \"QuizzardHandwriting\", sans-serif !important;\n}\n\n* {\n  font-family: \"QuizzardHandwriting\", sans-serif !important;\n}\n\n.App {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background-size: 200% 200%;\n  animation: backgroundShift 5s infinite alternate;\n}\n\n@keyframes backgroundShift {\n  0% {\n    background-position: 0% 50%;\n  }\n  100% {\n    background-position: 100% 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
