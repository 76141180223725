import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Search.css"; // Import a CSS file for styling
import Modal from "./Modal"; // Import the Modal component

function Search() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [quizzlist, setQuizzlist] = useState([]);
  const [isQuizzlistOpen, setIsQuizzlistOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState(null); // State to store userId

  useEffect(() => {
    // Fetch user data on component mount
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
          withCredentials: true
        });
        setUserId(response.data.id); // Assuming the user object has an 'id' field
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleSearch = async () => {
    if (!query || query.length < 3) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search`,
        {
          params: { query },
        }
      );
      const filteredResults = response.data.filter(
        (track) => track.preview_url
      );
      setResults(filteredResults);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };

  const addToQuizzlist = (track) => {
    setQuizzlist((prevList) => {
      if (!prevList.some((item) => item.id === track.id)) {
        return [...prevList, track];
      }
      return prevList;
    });
  };

  const removeFromQuizzlist = (trackId) => {
    setQuizzlist((prevList) =>
      prevList.filter((track) => track.id !== trackId)
    );
  };

  const clearQuizzlist = () => {
    setQuizzlist([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const clearSearch = () => {
    setQuery("");
    setResults([]);
  };

  const toggleQuizzlist = () => {
    setIsQuizzlistOpen(!isQuizzlistOpen);
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const saveQuizzlist = (name, description, quizzlist) => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/quizzlists`, {
        name,
        songs: quizzlist.map((track) => track.id),
        userId, // Include userId in the request body
        description,
      })
      .then((response) => {
        clearQuizzlist();
      })
      .catch((error) => {
        console.error("Error saving quizzlist:", error);
      });
    closeModal();
  };

  return (
    <div className="search-container">
      <div className="input-wrapper">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Search"
          className="search-input"
        />
        {query && (
          <span className="clear-icon" onClick={clearSearch}>
            &times;
          </span>
        )}
      </div>
      <button
        onClick={handleSearch}
        onTouchStart={handleSearch}
        className="search-button"
      >
        Search
      </button>
      <ul className="results-list">
        {results.map((track) => (
          <li key={track.id} className="result-item">
            <div className="track-info">
              {track.album &&
                track.album.images &&
                track.album.images.length > 0 && (
                  <img
                    src={
                      track.album.images.reduce((smallest, image) => {
                        return image.width < smallest.width ? image : smallest;
                      }).url
                    }
                    alt={`${track.name} thumbnail`}
                    className="track-thumbnail"
                  />
                )}
              <span className="track-text">
                {track.name} by {track.artists[0].name}
              </span>
              <button
                onClick={() => {
                  if (quizzlist.some((item) => item.id === track.id)) {
                    removeFromQuizzlist(track.id);
                  } else {
                    addToQuizzlist(track);
                  }
                }}
                className={
                  quizzlist.some((item) => item.id === track.id)
                    ? "remove-button"
                    : "add-button"
                }
              >
                {quizzlist.some((item) => item.id === track.id) ? "-" : "+"}
              </button>
            </div>
            {track.preview_url && (
              <div className="audio-player">
                <audio controls className="full-width-audio">
                  <source src={track.preview_url} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )}
          </li>
        ))}
      </ul>
      {!isModalOpen && quizzlist.length > 0 && (
        <div
          className={`quizzlist-window ${isQuizzlistOpen ? "open" : ""}`}
          onClick={toggleQuizzlist}
        >
          <div className="quizzlist-header">
            <h4>Quizzlist</h4>
            <button className="close-button" onClick={toggleQuizzlist}>
              X
            </button>
          </div>
          <ul>
            {(isQuizzlistOpen ? quizzlist : quizzlist.slice(0, 5)).map(
              (track, index) => (
                <li key={index} className="quizzlist-item">
                  <p className="quizzlist-item-name">
                    {track.name} by {track.artists[0].name}
                  </p>
                  {isQuizzlistOpen && (
                    <button
                      className="remove-button quizzlist-item-remove"
                      onClick={() => removeFromQuizzlist(track.id)}
                    >
                      -
                    </button>
                  )}
                </li>
              )
            )}
            {!isQuizzlistOpen && quizzlist.length > 4 && (
              <li className="show-more" onClick={toggleQuizzlist}>
                Show more...
              </li>
            )}
          </ul>
          {isQuizzlistOpen && (
            <div className="quizzlist-actions">
              <button className="clear-button" onClick={clearQuizzlist}>
                Clear All
              </button>
              <button className="save-button" onClick={openModal}>
                Save
              </button>
            </div>
          )}
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={(name, description) =>
          saveQuizzlist(name, description, quizzlist)
        } // Pass userId when saving
        quizzlist={quizzlist}
      />
    </div>
  );
}

export default Search;
