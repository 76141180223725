import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./SavedQuizzLists.css"; // Import a CSS file for styling

function SavedQuizzLists() {
  const [quizzLists, setQuizzLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedQuizz, setExpandedQuizz] = useState({}); // Track expanded state

  useEffect(() => {
    const fetchQuizzLists = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/quizzlists`
        );
        setQuizzLists(response.data);
      } catch (err) {
        setError("Failed to fetch quizz lists");
      } finally {
        setLoading(false);
      }
    };

    fetchQuizzLists();
  }, []);

  const toggleExpand = (id) => {
    setExpandedQuizz((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const removeQuizzList = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/quizzlists/${id}`);
      setQuizzLists((prevLists) =>
        prevLists.filter((quizz) => quizz.id !== id)
      );
    } catch (err) {
      console.error("Failed to delete quizz list", err);
      setError("Failed to delete quizz list");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="saved-quizz-lists">
      <h1>Saved Quizz Lists</h1>
      {quizzLists.length === 0 ? (
        <div className="empty-message">
          It's all empty! Go create a new one! <Link to="/search">Search</Link>
        </div>
      ) : (
        <ul>
          {quizzLists.map((quizz) => (
            <div key={quizz.id}>
              <div
                className="quizzlist-header"
                onClick={() => toggleExpand(quizz.id)}
              >
                <h2>{quizz.name}</h2>
                <p>{quizz.description}</p>
                <span
                  className={`chevron ${
                    expandedQuizz[quizz.id] ? "down" : "right"
                  }`}
                ></span>
                <button
                  onClick={() => removeQuizzList(quizz.id)}
                  className="remove-button"
                >
                  -
                </button>
              </div>
              <li key={quizz.id} className="quizzlist-item">
                {expandedQuizz[quizz.id] && (
                  <ul className="track-list">
                    {quizz.trackDetails.map((track) => (
                      <li key={track.id} className="result-item">
                        <div className="track-info">
                          {track.album &&
                            track.album.images &&
                            track.album.images.length > 0 && (
                              <img
                                src={
                                  track.album.images.reduce(
                                    (smallest, image) => {
                                      return image.width < smallest.width
                                        ? image
                                        : smallest;
                                    }
                                  ).url
                                }
                                alt={`${track.name} thumbnail`}
                                className="track-thumbnail"
                              />
                            )}
                          <span>
                            {track.name} by {track.artists[0].name}
                          </span>
                        </div>
                        {track.preview_url && (
                          <div className="audio-player">
                            <audio controls className="full-width-audio">
                              <source
                                src={track.preview_url}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SavedQuizzLists;
