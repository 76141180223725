import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css"; // Optional: Create a CSS file for styling

function Home() {
  const navigate = useNavigate();

  return (
    <div className="home-container">
      <img src="/logo.png" alt="Logo" className="logo" />
      <button onClick={() => navigate("/search")} className="home-button">
        Create Quizzlist
      </button>
      <button onClick={() => navigate("/play")} className="home-button">
        Play
      </button>
    </div>
  );
}

export default Home;
