import React, { useState } from "react";
import axios from "axios";

function Stream() {
  const [trackId, setTrackId] = useState("");
  const [trackData, setTrackData] = useState(null);

  const handleStream = async () => {
    try {
      const response = await axios.get(
        `http://localhost:3001/stream/${trackId}`
      );
      setTrackData(response.data);
    } catch (error) {
      console.error("Error streaming track", error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={trackId}
        onChange={(e) => setTrackId(e.target.value)}
        placeholder="Enter track ID"
      />
      <button onClick={handleStream}>Stream</button>
      {trackData && (
        <div>
          <h3>{trackData.name}</h3>
          <p>By {trackData.artists[0].name}</p>
          {/* Add audio player or other streaming logic here */}
        </div>
      )}
    </div>
  );
}

export default Stream;
