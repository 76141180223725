import React, { useState } from "react";
import axios from "axios";
import Game from "./Game"; // Import the Game component
import "./Play.css"; // Reuse styles from SavedQuizzLists

function Play() {
  const [searchTerm, setSearchTerm] = useState("");
  const [quizzLists, setQuizzLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedQuizz, setSelectedQuizz] = useState(null); // Track selected quizz

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/quizzlists?search=${searchTerm}`
      );
      setQuizzLists(response.data);
    } catch (err) {
      setError("Failed to fetch quizz lists");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (quizz) => {
    setSelectedQuizz(quizz); // Set the selected quizz
  };

  if (selectedQuizz) {
    return <Game quizz={selectedQuizz} />; // Render the Game component
  }

  return (
    <div className="play-component">
      <h1>Play Quizz</h1>
      <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for quizz lists..."
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {loading && <div>Loading...</div>}
      {error && <div>{error}</div>}
      <ul>
        {quizzLists.map((quizz) => (
          <li key={quizz.id} className="quizzlist-item">
            <div className="quizzlist-header">
              <h2>{quizz.name}</h2>
              <button
                onClick={() => handleButtonClick(quizz)}
                className="action-button"
              >
                &gt;
              </button>
            </div>
            <div className="created-by">
              <p>Created by: {quizz.createdByUsername}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Play;
