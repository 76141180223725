import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./components/Home";
import Search from "./components/Search";
import Stream from "./components/Stream";
import Login from "./components/Login";
import NavBar from "./components/NavBar";
import SavedQuizzLists from "./components/SavedQuizzLists";
import Play from "./components/Play";

import "./App.css";
import "./assets/fonts/QuizzardHandwriting-Regular.otf";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== "/" && <NavBar />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/stream" element={<Stream />} />
        <Route path="/play" element={<Play />}></Route>
        <Route path="/quizz-lists" element={<SavedQuizzLists />} />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
