import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import md5 from "md5";
import "./NavBar.css";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data from the /me endpoint
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/me`,
          {
            withCredentials: true,
          }
        );
        const user = response.data;
        setUser(user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Generate Gravatar URL
  const profilePicture = user?.profile_picture;

  return (
    <div className="navbar">
      <img
        src="/logo.png"
        alt="Logo"
        className="header-logo"
        onClick={() => navigate("/")}
      />
      {user.spotify_id && (
        <img
          src={profilePicture}
          alt="User Avatar"
          className="user-avatar"
          onClick={toggleMenu}
        />
      )}
      {isOpen && (
        <div className="menu">
          <div className="menu-header">
            <img
              src={profilePicture}
              alt="User Avatar"
              className="menu-avatar"
            />
            <div className="menu-username">{user.spotify_id}</div>
          </div>
          <NavLink to="/profile" onClick={toggleMenu}>
            Profile
          </NavLink>
          <NavLink to="/quizz-lists" onClick={toggleMenu}>
            QuizzLists
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default NavBar;
