import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";

function Login() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/login`;
  };

  async function getToken() {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/token`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const json = await response.json();
    setToken(json.access_token);
    if (json.access_token) {
      navigate("/home");
    }
  }
  const [token, setToken] = useState("");

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div className="login-container">
      <img src="/logo.png" alt="Logo" className="logo" />
      {token ? (
        <div className="token-display">Token: {token}</div>
      ) : (
        <button className="spotify-login-button" onClick={handleButtonClick}>
          <FontAwesomeIcon icon={faSpotify} /> Login with spotify
        </button>
      )}
    </div>
  );
}

export default Login;
